import { ReactNode } from "react";
import {
	StyledInput,
	StyledInputSuffix,
	StyledInputWrapper,
	StyledLabel,
} from "./Input.styled";

export const InputComponent = ({ suffix = false, ...rest }: InputProps) => {
	return (
		<StyledInputWrapper>
			<StyledInput suffix={suffix} {...rest} />
			{suffix && <StyledInputSuffix>{suffix}</StyledInputSuffix>}
		</StyledInputWrapper>
	);
};

export type InputProps = {
	suffix?: ReactNode;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Input = Object.assign(InputComponent, {
	Label: StyledLabel,
});
