import { FC, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import {
	AuthStatus,
	retrieveAuth,
	useAuthDispatch,
	useAuthState,
} from "../context/auth";
import { getAuthUrl } from "../api/auth";

const RequireAuth: FC<RequireAuthProps> = ({ children }) => {
	const dispatch = useAuthDispatch();
	const { user, status } = useAuthState() ?? {};

	useEffect(() => {
		if (!user && AuthStatus.IDLE && !!dispatch) {
			retrieveAuth(dispatch);
		}
	}, [user, dispatch]);

	if (user) {
		return children;
	}

	if (status === AuthStatus.PENDING || status === AuthStatus.IDLE) {
		return null;
	}

	return <RedirectToAuth />;
};

const RedirectToAuth: FC<{}> = () => {
	const [authUrl, setAuthUrl] = useState<string>("");

	useEffect(() => {
		(async () => {
			const urlRes = await getAuthUrl();
			setAuthUrl(urlRes.url);
		})();
	}, []);

	if (authUrl) {
		window.location.href = authUrl;
	}

	return null;
};

export interface RequireAuthProps {
	children: JSX.Element;
}

export default RequireAuth;
