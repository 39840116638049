import styled from "styled-components";
import { InputProps } from "./Input";

export const StyledInputWrapper = styled.div`
	display: flex;
	outline: 0px solid rgba(0, 0, 0, 0);
	transition: outline 0.2s ease-in-out;
	border-radius: 5px;
	&:hover,
	&:focus {
		border-color: #000;
		outline: 5px solid rgba(0, 0, 0, 0.1);
	}
`;

export const StyledInput = styled.input<InputProps>`
	all: unset;
	display: block;
	width: 300px;
	padding: 12px;
	border: 1px solid #eee;
	border-radius: ${(props) => (!!props.suffix ? "5px 0 0 5px" : "5px")};
	${(props) => (!!props.suffix ? "border-right: none;" : "")}
`;

export const StyledInputSuffix = styled.div`
	display: flex;
	align-items: center;
	border-radius: 0 5px 5px 0;
	padding: 0 6px;
	background-color: #f8f8f8;
	border: 1px solid #eee;
	color: #666;
`;

export const StyledLabel = styled.label`
	display: block;
	margin-bottom: 8px;
`;
