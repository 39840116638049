import styled from "styled-components";

export const StyledImage = styled.div`
	display: block;
	height: fit-content;
	border-radius: 5px;
	overflow: hidden;
	border: 1px solid #eee;
	cursor: pointer;

	& > img {
		height: 240px;
		width: 100%;
		object-fit: cover;
	}
`;

export const StyledImageInfo = styled.div`
	padding: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	& > div > div {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 200px;
		margin-bottom: 4px;
	}
`;
