import { useEffect, useState } from "react";
import { getImages, ImageListResponse } from "../api/image";
import { Image } from "./Image";
import { StyledImagesGrid } from "./ImagesGrid.styled";
import InfiniteScroll from "react-infinite-scroll-component";

const pageSize = 6;

export const ImagesGrid = () => {
	const [images, setImages] = useState<ImageListResponse>([]);
	const [pageNo, setPageNo] = useState<number>(0);
	const [hasMore, setHasMore] = useState<boolean>(true);

	useEffect(() => {
		setPageNo(0);
		setHasMore(true);
		try {
			(async () => setImages(await getImages(0, pageSize)))();
		} catch (e) {}
	}, []);

	const fetchData = async () => {
		try {
			const newImages = await getImages(pageNo + 1, pageSize);
			setPageNo(pageNo + 1);
			if (newImages?.length === 0) {
				setHasMore(false);
			}
			setImages([...images, ...newImages]);
		} catch (e) {}
	};

	return (
		<div style={{ flex: 1 }}>
			<InfiniteScroll
				dataLength={images.length} //This is important field to render the next data
				next={fetchData}
				hasMore={hasMore}
				loader={<>Loading...</>}
			>
				<StyledImagesGrid>
					{images.map(({ name, link }) => (
						<Image
							key={link}
							name={name}
							src={link}
							onDelete={(src) =>
								setImages(images.filter((i) => i.link !== src))
							}
						/>
					))}
				</StyledImagesGrid>
			</InfiniteScroll>
		</div>
	);
};
