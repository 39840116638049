import styled from "styled-components";

const StyledNavbar = styled.div`
	width: 100%;
	height: 64px;
	background-color: #fff;
	border-bottom: 1px solid #eee;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledNavbarContentWrapper = styled.div`
	max-width: 992px;
	flex: 1;
	margin: 16px;
	justify-content: space-between;

	display: flex;
	align-items: center;

	.title {
		all: unset;
		padding: 16px 16px 16px 0;
		cursor: pointer;
	}

	.actions {
		display: flex;
		gap: 8px;
	}
`;

export { StyledNavbar, StyledNavbarContentWrapper };
