import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "../context/auth";
import { Button } from "./Button.styled";
import { StyledNavbar, StyledNavbarContentWrapper } from "./Navbar.styled";
import { logout } from "../api/auth";

const Navbar = () => {
	const navigate = useNavigate();
	const { user } = useAuthState() ?? {};

	return (
		<StyledNavbar>
			<StyledNavbarContentWrapper>
				<Link className="title" to="/">
					Framedpizza
				</Link>
				{!!user && (
					<div className="actions">
						<Button onClick={() => navigate("/upload")}>Upload</Button>
						<Button onClick={() => logout()}>Log out</Button>
					</div>
				)}
			</StyledNavbarContentWrapper>
		</StyledNavbar>
	);
};

export default Navbar;
