import styled from "styled-components";

const Button = styled.button`
	max-width: fit-content;
	display: block;
	all: unset;
	padding: 12px;
	background-color: #000;
	color: #fff;
	font-weight: bold;
	transition: outline 0.2s ease-in-out;
	border-radius: 5px;
	outline: 0px solid rgba(0, 0, 0, 0);
	text-align: center;

	&:hover,
	&:focus {
		outline: 5px solid rgba(0, 0, 0, 0.1);
	}
`;

export { Button };
