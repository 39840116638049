import styled from "styled-components";

export const StyledImageUploader = styled.div`
	border-radius: 5px;
	border: 1px solid #eee;
	padding: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 400px;
	min-width: 300px;

	img {
		display: block;
		max-width: 200px;
		margin: 32px 0;
		border-radius: 10px;
		overflow: hidden;
	}

	a {
		margin-top: 32px;
		max-width: 200px;
		word-break: break-all;
	}
`;

export const StyledUploadControls = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
`;
