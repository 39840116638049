import client from "./client";

export interface ImageUploadResponse {
	link: string;
}

export type ImageListResponse = Array<ImageResponse>;

export interface ImageResponse {
	name: string;
	link: string;
	expireAt: Date;
}

export const uploadImage = async (
	file: File,
	customName?: string,
	callback?: (res: ImageUploadResponse) => void
): Promise<ImageUploadResponse> => {
	const data = new FormData();
	const ext = file.name.split('.').pop();
	data.append("image", file, !!customName ? `${customName}.${ext}` : "");
	const response = await client.post("/image/upload", data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	callback?.(response.data);
	return response.data;
};

export const getImages = async (page_no: Number, page_size: Number): Promise<ImageListResponse> => {
	const response = await client.get(`/image?page_no=${page_no}&page_size=${page_size}`);
	return response?.data?.map((image: ImageResponse) => ({
		...image,
		expireAt: new Date(image.expireAt),
	}));
};

export const deleteImage = async (key: string, name: string) => {
	await client.delete(`/image/${key}/${name}`);
};
