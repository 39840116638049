import { BrowserRouter, Routes, Route } from "react-router-dom";
import ImageUploader from "./components/ImageUploader";
import RequireAuth from "./components/RequireAuth";
import StandardBody from "./components/StandardBody";
import { AuthProvider } from "./context/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ImagesGrid } from "./components/ImagesGrid";

function App() {
	return (
		<AuthProvider>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<StandardBody />}>
						<Route
							index
							element={
								<RequireAuth>
									<ImagesGrid />
								</RequireAuth>
							}
						/>
						<Route
							path="/upload"
							element={
								<RequireAuth>
									<ImageUploader />
								</RequireAuth>
							}
						/>
					</Route>
				</Routes>
			</BrowserRouter>
			<ToastContainer />
		</AuthProvider>
	);
}

export default App;
