import { toast } from "react-toastify";
import { StyledImage, StyledImageInfo } from "./Image.styled";
import humanize from "humanize-duration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button } from "./Button.styled";
import { SyntheticEvent } from "react";
import { deleteImage } from "../api/image";
import { AxiosError } from "axios";

export const Image = ({ src, name, expireAt, onDelete }: ImageProps) => {
	const handleClick = () => {
		try {
			navigator.clipboard.writeText(src);
			toast("Copied link to clipboard!");
		} catch (e) {}
	};

	const handleDelete = async (e: SyntheticEvent) => {
		e.stopPropagation();
		const linkArr = src.split("/");
		const name = linkArr.pop();
		const key = linkArr.pop();
		try {
			if (!!key && !!name) {
				await deleteImage(key, name);
				toast("Deleted image successfully");
				onDelete?.(src);
			}
		} catch (e) {
			if (e instanceof AxiosError) {
				toast(e.response?.data?.message);
			}
		}
	};

	return (
		<StyledImage onClick={handleClick}>
			<img src={src} alt={name} />
			<StyledImageInfo>
				<div>
					<div>{name}</div>
					{expireAt && (
						<div>
							{`${humanize(new Date().getTime() - expireAt?.getTime(), {
								largest: 1,
							})} remaining`}
						</div>
					)}
				</div>
				<Button onClick={handleDelete}>
					<FontAwesomeIcon icon={faTrash} />
				</Button>
			</StyledImageInfo>
		</StyledImage>
	);
};

interface ImageProps {
	src: string;
	name: string;
	expireAt?: Date;
	onDelete?: (src: string) => void;
}
