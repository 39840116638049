import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import {
	StyledStandardBody,
	StyledContentWrapper,
} from "./StandardBody.styled";

const StandardBody = () => {
	return (
		<StyledStandardBody>
			<Navbar />
			<StyledContentWrapper>
				<Outlet />
			</StyledContentWrapper>
		</StyledStandardBody>
	);
};

export default StandardBody;
