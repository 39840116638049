import { createBrowserHistory } from "history";
import {
	createContext,
	Dispatch,
	ReactNode,
	useContext,
	useReducer,
} from "react";
import { getUser, login, LoginRequestData, User } from "../api/auth";

const history = createBrowserHistory();

const AuthStateContext = createContext<AuthState | null>(null);
const AuthDispatchContext = createContext<Dispatch<AuthState> | null>(null);

export const enum AuthStatus {
	IDLE = "idle",
	PENDING = "pending",
	REJECTED = "rejected",
	RESOLVED = "resolved",
}

export interface AuthState {
	status?: AuthStatus;
	user?: User | null;
	error?: any;
}

const initialState = {
	status: AuthStatus.IDLE,
	user: null,
	error: null,
};

const reducer = (currentState: AuthState, newState: AuthState) => {
	return { ...currentState, ...newState };
};

const useAuthState = () => {
	const context = useContext(AuthStateContext);

	return context;
};

const useAuthDispatch = () => {
	const context = useContext(AuthDispatchContext);

	return context;
};

const AuthProvider = ({ children }: { children: ReactNode }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	return (
		<AuthStateContext.Provider value={state}>
			<AuthDispatchContext.Provider value={dispatch}>
				{children}
			</AuthDispatchContext.Provider>
		</AuthStateContext.Provider>
	);
};

const doLogin = async (
	dispatch: Dispatch<AuthState>,
	user: LoginRequestData
) => {
	try {
		dispatch({ status: AuthStatus.PENDING });

		const result = await login(user);

		dispatch({
			status: AuthStatus.RESOLVED,
			user: result,
			error: null,
		});
	} catch (error) {
		dispatch({ status: AuthStatus.REJECTED, error });
	}
};

const retrieveAuth = async (dispatch: Dispatch<AuthState>) => {
	try {
		dispatch({ status: AuthStatus.PENDING });

		const user = await getUser();

		dispatch({
			status: AuthStatus.RESOLVED,
			user: user,
			error: null,
		});
	} catch (error) {
		dispatch({ status: AuthStatus.REJECTED, error });
	}
};

const doLogout = (dispatch: React.Dispatch<any>) => {
	dispatch(initialState);
	history.push("/login");
};

export {
	AuthProvider,
	useAuthState,
	useAuthDispatch,
	doLogin,
	doLogout,
	retrieveAuth,
};
