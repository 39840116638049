import client from "./client";

export interface AuthUrl {
	url: string;
}

export interface User {
	email: string;
	id?: string;
}

export interface LoginRequestData {
	email: string;
	password: string;
}

const getUser = async (): Promise<User> => {
	const response = await client.get("/user/me");
	return response.data;
};

const getAuthUrl = async (): Promise<AuthUrl> => {
	const response = await client.get("/auth/url");
	return response.data;
};

const login = async (data: LoginRequestData): Promise<User> => {
	const response = await client.post("/auth/login", data);
	return response.data;
};

const logout = async (): Promise<{}> => {
	const response = await client.get("/auth/logout");
	return response.data;
};

export { getUser, login, getAuthUrl, logout };
