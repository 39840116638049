import styled from "styled-components";

const StyledStandardBody = styled.div`
	width: 100vw;
	min-height: 100vh;
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const StyledContentWrapper = styled.div`
	display: flex;
	justify-content: center;
	max-width: 992px;
	width: 100%;
	margin: 16px;
`;

export { StyledStandardBody, StyledContentWrapper };
