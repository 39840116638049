import { Fragment, useEffect, useState } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { toast } from "react-toastify";
import { ImageUploadResponse, uploadImage } from "../api/image";
import { Button } from "./Button.styled";
import {
	StyledImageUploader,
	StyledUploadControls,
} from "./ImageUploader.styled";
import { Input } from "./Input";
import { AxiosError } from "axios";

const ImageUploader = () => {
	const [images, setImages] = useState<ImageListType>([]);
	const [link, setLink] = useState<string | null>(null);
	const [name, setName] = useState<string>("");

	useEffect(() => {
		if (!!images.length) {
			const name = images[0].file?.name?.replace(/\.[^/.]+$/, "") ?? "";
			setName(name);
		}
	}, [images]);

	const handleChange = (imageList: ImageListType) => {
		setImages(imageList);
	};

	const handleSubmit = async () => {
		try {
			if (!!images[0]?.file) {
				await uploadImage(images[0].file, name, handleSuccess);
			}
		} catch (e) {
			if (e instanceof AxiosError) {
				toast(e.response?.data?.message);
			}
		}
	};

	const handleSuccess = (data: ImageUploadResponse) => {
		const linkText = `${process.env.REACT_APP_IMAGES_URL}/${data.link}`;
		setImages([]);
		setLink(linkText);
		try {
			navigator.clipboard.writeText(linkText);
			toast("Copied link to clipboard!");
		} catch (e) {
			toast("Uploaded!");
		}
	};

	return (
		<StyledImageUploader>
			<ImageUploading
				multiple={false}
				value={images}
				onChange={handleChange}
				dataURLKey="data_url"
			>
				{({ imageList, onImageUpload, dragProps }) => (
					<>
						<Button onClick={onImageUpload} {...dragProps}>
							Click or Drop here
						</Button>
						{imageList.map((image, idx) => (
							<StyledUploadControls key={idx}>
								<img src={image["data_url"]} alt="uploaded" />
								<div>
									<Input.Label htmlFor="fileName">File name</Input.Label>
									<Input
										id="fileName"
										suffix={`.${image.file?.name?.split(".").pop()}`}
										onChange={(e) => setName(e.target.value.trim())}
										value={name}
									/>
								</div>
								<Button onClick={handleSubmit}>Upload</Button>
							</StyledUploadControls>
						))}
					</>
				)}
			</ImageUploading>
			{link && <a href={link}>{link}</a>}
		</StyledImageUploader>
	);
};

export default ImageUploader;
